import clsx from 'clsx'
import Typography from 'components/Common/Typography'
import SlippageInput from 'components/SlippageInput/SlippageInput'
import { DEFAULT_SLIPPAGE_AMOUNT } from 'config/factors'
import { useState } from 'react'

const visibleSlippagePercentages = [
  { label: '0.5', value: 50 },
  { label: '1.0', value: 100 },
  { label: '1.5', value: 150 },
  { label: '2', value: 200 },
]

const SlippagePercentageSelector = ({
  slippagePercentage,
  changeSlippagePercentage,
}: {
  slippagePercentage: number
  changeSlippagePercentage: (val: number) => void
}) => {
  const [isEditing, setIsEditing] = useState(false)

  return (
    <div className="flex h-8 items-center gap-2.5">
      {visibleSlippagePercentages.map((el, idx) => {
        return (
          <div
            key={idx}
            className={clsx(
              'flex h-full flex-1 items-center justify-center rounded border border-th-input-border',
              el.value === slippagePercentage
                ? 'bg-th-fgd-1'
                : 'cursor-pointer bg-th-bkg-2',
            )}
            onClick={() => {
              changeSlippagePercentage(el.value)
              setIsEditing(false)
            }}
          >
            <Typography
              font="font-number"
              className={clsx(
                'text-sm font-semibold',
                el.value === slippagePercentage
                  ? 'text-th-bkg-1'
                  : 'text-th-fgd-3',
              )}
            >{`${el.label}%`}</Typography>
          </div>
        )
      })}
      {isEditing ? (
        <div className="h-full flex-1">
          <SlippageInput
            // setAllowedSlippage={changeSlippagePercentage}
            defaultSlippage={DEFAULT_SLIPPAGE_AMOUNT}
            largeSize={true}
          />
        </div>
      ) : (
        <div
          className="flex h-full cursor-pointer items-center justify-center rounded border border-th-input-border bg-th-bkg-2 px-4"
          onClick={() => {
            setIsEditing(true)
            changeSlippagePercentage(DEFAULT_SLIPPAGE_AMOUNT)
          }}
        >
          <span className="text-sm font-semibold text-th-fgd-3">Custom</span>
        </div>
      )}
    </div>
  )
}

export default SlippagePercentageSelector
