import clsx from 'clsx'
import Typography from 'components/Common/Typography'
import { PropsWithChildren } from 'react'

type Props = {
  text: string
  subText?: string
  className?: string
}

const ExchangeInfoRowValue = ({
  text,
  subText,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div className={clsx('flex items-center', className)}>
      <Typography
        font="font-number"
        className="text-sm font-medium text-th-fgd-1"
      >
        {text}
      </Typography>
      {subText && (
        <p className="w-32 text-end text-sm font-medium text-th-fgd-3">
          &nbsp;({subText})
        </p>
      )}
    </div>
  )
}

export default ExchangeInfoRowValue
