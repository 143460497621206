import { Field, Label, Radio, RadioGroup } from '@headlessui/react'
import { uniq } from 'lodash'

interface RadioButtonsProps {
  options: string[]
  selected: string
  setSelected: (selected: string) => void
}

export function RadioButtons({
  options,
  selected,
  setSelected,
}: RadioButtonsProps) {
  return (
    <div className="my-3 flex flex-col">
      <span className="font-body text-sm font-medium text-th-fgd-3">
        Receive In Terms of
      </span>
      <RadioGroup
        value={selected}
        onChange={setSelected}
        className="mt-2 flex justify-between"
      >
        {uniq(options).map((option) => (
          <Field key={option} className="flex items-center gap-2">
            <Radio
              value={option}
              className="group flex h-3 w-3 items-center justify-center rounded-full border bg-white data-[checked]:bg-th-active"
            >
              <span className="size-2 invisible rounded-full bg-white group-data-[checked]:visible" />
            </Radio>
            <Label>{option}</Label>
          </Field>
        ))}
      </RadioGroup>
    </div>
  )
}
